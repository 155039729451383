// extracted by mini-css-extract-plugin
export var bgGrey100 = "NewsletterSubscribe-module--bg-grey-100--b9c75";
export var bgGrey150 = "NewsletterSubscribe-module--bg-grey-150--c09f2";
export var bgGrey200 = "NewsletterSubscribe-module--bg-grey-200--dee28";
export var bgGrey300 = "NewsletterSubscribe-module--bg-grey-300--67fa8";
export var bgGrey400 = "NewsletterSubscribe-module--bg-grey-400--34a47";
export var bgGrey500 = "NewsletterSubscribe-module--bg-grey-500--c6c97";
export var bgGrey600 = "NewsletterSubscribe-module--bg-grey-600--0f03e";
export var bgGrey700 = "NewsletterSubscribe-module--bg-grey-700--b68b9";
export var bgGrey800 = "NewsletterSubscribe-module--bg-grey-800--d6b44";
export var bgGrey900 = "NewsletterSubscribe-module--bg-grey-900--e39f1";
export var form = "NewsletterSubscribe-module--form--28af1";
export var formControl = "NewsletterSubscribe-module--formControl--23ca7";
export var row = "NewsletterSubscribe-module--row--5d88d";
export var success = "NewsletterSubscribe-module--success--75422";
export var textGrey100 = "NewsletterSubscribe-module--text-grey-100--b7bd5";
export var textGrey150 = "NewsletterSubscribe-module--text-grey-150--fac03";
export var textGrey200 = "NewsletterSubscribe-module--text-grey-200--ad66e";
export var textGrey300 = "NewsletterSubscribe-module--text-grey-300--94d50";
export var textGrey400 = "NewsletterSubscribe-module--text-grey-400--50e6d";
export var textGrey500 = "NewsletterSubscribe-module--text-grey-500--b50cb";
export var textGrey600 = "NewsletterSubscribe-module--text-grey-600--17def";
export var textGrey700 = "NewsletterSubscribe-module--text-grey-700--10096";
export var textGrey800 = "NewsletterSubscribe-module--text-grey-800--fc4db";
export var textGrey900 = "NewsletterSubscribe-module--text-grey-900--fd09c";