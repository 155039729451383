import { graphql } from "gatsby"
import React, { useState } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Form, Button } from "react-bootstrap"
import axios from "axios"
import queryString from "query-string"
import * as style from "./NewsletterSubscribe.module.scss"

export const fragment = graphql`
  fragment NewsletterSubscribeFragment on WpPage_Flexlayouts_FlexibleLayouts_NewsletterSubscribe {
    sectionHeader
  }
`

const NewsletterSubscribe = ({ sectionHeader }) => {
  const [status, setStatus] = useState({ initial: true })
  const [formValidated, setFormValidated] = useState(false)

  const email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i

  const validate = event => {
    const form = event.currentTarget
    if (email_regex.test(form.elements.email.value)) {
      event.stopPropagation()
      setFormValidated(true)
    } else {
      setFormValidated(false)
    }
  }


  const handleSubmit = event => {
    event.preventDefault()

    // form validation
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.stopPropagation()
      setFormValidated(false)
      return
    } else {
      setFormValidated(true)
    }

    const data = {
      email: form.elements.email.value,
    }

    const postForm = async () => {
      try {
        await axios.post(
          `${process.env.NEWSLETTER_FORM_URL}`,
          queryString.stringify(data)
        )
        setStatus({
          success: `Thank you for subscribing to our newsletter.`,
          initial: false,
        })
      } catch (exception) {
        setStatus({
          ...status,
          exception: `${exception.response.status}: ${exception.response.statusText}`,
        })
      }
    }
    postForm()
  }

  return (
    <div className="mx-5">
      <Row className={style.row}>
        <Col md={12} lg={5} xl={4}>
          <h2
            className="my-4 m-lg-0 text-center text-uppercase"
            dangerouslySetInnerHTML={{ __html: sectionHeader }}
          />
        </Col>
        <Col md={12} lg={7} xl={8}>
          <Form
            className={style.form}
            id="newsletterForm"
            validated={formValidated}
            onSubmit={handleSubmit}
            onChange={validate}
            method="post">
            <Row className={`flex-column flex-lg-row`}>
              <Col lg={9} className="pr-lg-0">
                {status && status.initial && (
                  <Form.Group controlId="email">
                    <Form.Control
                      className={style.formControl}
                      type="email"
                      name="email"
                      placeholder="Enter your email to receive promotions"
                      required
                    />
                  </Form.Group>
                )}
                {status && status.success && (
                  <div className={style.success}>
                    <span>{status.success}</span>
                  </div>
                )}
              </Col>
              {status && !status.success ? (
                <Col lg={3} className="pl-lg-0">
                  <Button type="submit" disabled={!formValidated} className={`w-100`}>
                    Subscribe
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default NewsletterSubscribe
